
import http from "../http-common";
import AbstractService from "../services/AbstractService.js";

export default class TermsConditionsService extends AbstractService {
    getAll() {
        return http.get("/termsConditions/all");
    }

    get(id) {
        return http.get(`/termsConditions/${id}`);
    }

    getLazy(params) {
        params = this.filteredLazyParams(params)
        return http.get("/termsConditions/lazy", { params });
    }

    create(data) {
        return http.post("/termsConditions", data);
    }

    update(id, data) {
        return http.put(`/termsConditions/${id}`, data);
    }

    delete(id) {
        return http.delete(`/termsConditions/${id}`);
    }

    getByTransactionType(transactionType, orgId) {
        return http.get(`/termsConditions/byTransactionType/${transactionType}/${orgId}`);
    }

    reorder(data) {
        let res = { "data": data }
        return http.post(`/termsConditions/reorder`, JSON.stringify(res));
    }

    async getByOrgCode(orgCode) {
        return http.get(`/termsConditions/${orgCode}`);
    }
}