export default class AbstractService {
    filteredLazyParams(lazyParams) {
        if (lazyParams == null) {
            return {}
        }
        if (lazyParams.filters != null) {
            let filteredValue = Object.keys(lazyParams.filters)
                .filter(key => lazyParams.filters[key].value !== "")
                .reduce((acc, key) => {
                    acc[key] = lazyParams.filters[key];
                    return acc;
                }, {});

            lazyParams.filters = filteredValue
        }
        return lazyParams;
    }

}