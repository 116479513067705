import { useAuthStore } from "@/stores/auth.store.js";
import { useWebNotification } from "@vueuse/core";
import { defineStore } from "pinia";

export const useSocketStore = defineStore("websocket", {
  state: () => ({
    socket: null,
    isConnected: false,
    messages: [],
  }),
  actions: {
    connect() {
      if (!this.socket) {
        const auth = useAuthStore();
        if (auth.user.username == null || auth.org.code == null) {
          return;
        }
        let hostname = window.location.host;
        let protocol = window.location.protocol;
        let sockeBasetUrl =
          window.location.hostname === "localhost"
            ? "ws://localhost:7070/notificationSocket"
            : (protocol === "https:" ? "wss:" : "ws:") + "//" + hostname + "/notificationSocket";

        this.socket = new WebSocket(
          sockeBasetUrl + `/${auth?.org?.code}/${auth?.user?.username}`
        );

        this.socket.onopen = () => {
          console.log("connected");
          this.isConnected = true;
        };

        this.socket.onmessage = (event) => {
          const message = JSON.parse(event.data);
          if (message !== true) {
            this.messages.push(message);
            this.showNotification(message);
            let deliveredData = {
              notificationUserId: message.id,
              socketMessageType: "DELIVERED",
            };
            this.sendMessage(deliveredData);
          }
        };

        this.socket.onerror = (error) => {
          console.error("WebSocket error:", error);
        };

        this.socket.onclose = () => {
          console.error("socket disconnected");
          this.isConnected = false;
        };
      }
    },
    showNotification(message) {
      try {
        const {
          isSupported,
          notification,
          show,
          close,
          onClick,
          onShow,
          onError,
          onClose,
        } = useWebNotification({
          title: message?.title,
          body: message?.message,
          icon: "/layout/images/logo.svg",
          silent: false,
        });
        show();
        onClick(() => {
          {
            this.handleNotificationMessage(message);
          }
        });

        let deliveredData = {
          notificationUserId: message.id,
          socketMessageType: "DELIVERED",
        };
        this.sendMessage(deliveredData);
      } catch (error) {
        console.error("Error showing notification:", error);
      }
    },
    sendMessage(message) {
      if (this.isConnected) {
        this.socket.send(JSON.stringify(message));
      } else {
        console.warn(
          "WebSocket connection not established, cannot send message"
        );
      }
    },
    destroySocket() {
      if (this.socket) {
        this.socket.close(); // Gracefully close the socket
        this.socket = null;
        this.isConnected = false;
      }
    },
    reconnect() {
      this.socket = null;
      this.isConnected = false;
      this.messages = [];
      this.connect();
    },
    handleNotificationMessage(message) {
      if (WebSocket.OPEN) {
        let data = {
          notificationUserId: message.id,
          socketMessageType: "READ",
        };
        this.sendMessage(data);
      } else {
        console.error("WebSocket connection is not open.\nReconnecting.....");
        this.reconnect();
      }
    },
  },
});
