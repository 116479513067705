import http from "../http-common";
import AbstractService from "../services/AbstractService.js";

export default class GlobalSettingsService extends AbstractService {
    getAll() {
        return http.get("/globalSettings/all");
    }

    get(id) {
        return http.get(`/globalSettings/${id}`);
    }

    getLazy(params) {
        params = this.filteredLazyParams(params)
        return http.get("/globalSettings/lazy", {params});
    }

    create(data) {
        return http.post("/globalSettings", data);
    }

    update(id, data) {
        return http.put(`/globalSettings/${id}`, data);
    }

    delete(id) {
        return http.delete(`/globalSettings/${id}`);
    }

    getAllByOrganization(orgCode) {
        return http.get(`/globalSettings/organization/${orgCode}`);
    }

    getOrgGlobalSettingsByName(orgCode, name) {
        return http.get(`/globalSettings/organization/${orgCode}/name/${name}`);
    }

}